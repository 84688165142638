<template>
  <div class="how-it-works">
    <BaseTitleSection :title="title" class="how-it-works__heading" />
    <div class="how-it-works__wrapper">
      <div v-for="item in list" :key="item.number" class="how-it-works__item">
        <div class="how-it-works__number" :class="[item.number_background]">
          <span>{{ item.number }}</span>
        </div>
        <p class="how-it-works__title">
          {{ item.title }}
        </p>
        <p class="how-it-works__desc">
          {{ item.desc }}
        </p>
        <div class="how-it-works__img">
          <BaseImg
            :src="item.img"
            :sizes="{lg:['3422','188'],xs: ['296','160']}"
            lazy
            class="w-full"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeHowItWorks',
  data () {
    return {
      title: 'How Ostado works?'
    }
  },
  computed: {
    list () {
      return [
        {
          number: 1,
          number_background: 'bg-teal',
          title: 'Select your ideal tutor',
          desc: 'Discover your perfect tutor from 1500+ top-quality options',
          img: [require('@images/public/home/how-booking-work/1-mobile.webp'),
            require('@images/public/home/how-booking-work/1.webp')]
        },
        {
          number: 2,
          number_background: 'bg-pink',
          title: 'Book a lesson',
          desc: 'Select a time on tutors calendar and book your classes.',
          img: [
            require('@images/public/home/how-booking-work/2-mobile.webp'),
            require('@images/public/home/how-booking-work/2.webp')
          ]
        },
        {
          number: 3,
          number_background: 'bg-green',
          title: 'Now learn!',
          desc: 'Learn easier with Ostado. Give us your feedback for better experience',
          img: [require('@images/public/home/how-booking-work/3-mobile.webp'),
            require('@images/public/home/how-booking-work/3.webp')]
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.how-it-works {
  @apply container text-left;
  &__heading {
    @apply mb-4;
  }
  &__wrapper {
    @apply flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0;
  }
  &__item {
    @apply p-6 border border-primary-100 rounded-lg;
  }
  &__number {
    @apply rounded-lg w-10 h-10 lg:w-12 lg:h-12 text-white flex items-center justify-center
    text__headline-4-md--bold lg:text__headline-3-md--bold;
    text-shadow: 3px 2px black;
  }
  &__title {
    @apply text__headline-4-xs--bold lg:text__headline-4-md--bold mt-4 text-body-1;
  }
  &__desc {
    @apply text__caption-xs--regular lg:text__body-2-md--regular mt-2 text-body-2;
  }
  &__img {
    @apply mt-4 lg:mt-8;
  }
}
</style>
